import { ButtonSize, ButtonSizeType, ButtonTheme, ButtonThemeType } from '@aimmo/design-system/aim-button-model/src';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[aimButtonHosting]',
  standalone: true
})
export class AimButtonHostingDirective {
  @Input() public buttonTheme: ButtonThemeType = ButtonTheme.primary;
  @Input() public buttonSize: ButtonSizeType = ButtonSize.medium;

  @HostBinding(`class.${ButtonSize.extraSmall}`)
  private get extraSmall(): boolean {
    return this.buttonSize === ButtonSize.extraSmall;
  }

  @HostBinding(`class.${ButtonSize.small}`)
  private get small(): boolean {
    return this.buttonSize === ButtonSize.small;
  }

  @HostBinding(`class.${ButtonSize.medium}`)
  private get medium(): boolean {
    return this.buttonSize === ButtonSize.medium;
  }

  @HostBinding(`class.${ButtonSize.large}`)
  private get large(): boolean {
    return this.buttonSize === ButtonSize.large;
  }

  @HostBinding(`class.${ButtonSize.extraLarge}`)
  private get extraLarge(): boolean {
    return this.buttonSize === ButtonSize.extraLarge;
  }

  @HostBinding(`class.${ButtonSize.parent}`)
  private get parent(): boolean {
    return this.buttonSize === ButtonSize.parent;
  }

  @HostBinding(`class.${ButtonTheme.primary}`)
  private get primary(): boolean {
    return this.buttonTheme === ButtonTheme.primary;
  }

  @HostBinding(`class.${ButtonTheme.secondary}`)
  private get secondary(): boolean {
    return this.buttonTheme === ButtonTheme.secondary;
  }

  @HostBinding(`class.${ButtonTheme.outline}`)
  private get outline(): boolean {
    return this.buttonTheme === ButtonTheme.outline;
  }

  @HostBinding(`class.${ButtonTheme.outlineBright}`)
  private get outlineBright(): boolean {
    return this.buttonTheme === ButtonTheme.outlineBright;
  }

  @HostBinding(`class.${ButtonTheme.ghost}`)
  private get ghost(): boolean {
    return this.buttonTheme === ButtonTheme.ghost;
  }

  @HostBinding(`class.${ButtonTheme.outlineLight}`)
  private get outlineLight(): boolean {
    return this.buttonTheme === ButtonTheme.outlineLight;
  }

  @HostBinding(`class.${ButtonTheme.primaryLight}`)
  private get primaryLight(): boolean {
    return this.buttonTheme === ButtonTheme.primaryLight;
  }
}
