export const ButtonTheme = {
  primary: 'primary',
  primaryLight: 'primary-light',
  secondary: 'secondary',
  outline: 'outline',
  outlineBright: 'outline-bright',
  outlineLight: 'outline-light',
  ghost: 'ghost'
} as const;
export type ButtonThemeType = typeof ButtonTheme[keyof typeof ButtonTheme];
export const ButtonSize = {
  extraSmall: 'extra-small',
  small: 'small',
  medium: 'medium',
  extraLarge: 'extra-large',
  large: 'large',
  parent: 'parent'
} as const;
export type ButtonSizeType = typeof ButtonSize[keyof typeof ButtonSize];

export const SementicStatus = {
  warning: 'warning',
  success: 'success',
} as const;
export type SementicStatusType = keyof typeof SementicStatus;

export const ButtonAlign = {
  start: 'start',
  center: 'center',
  end: 'end',
  spaceBetween: 'spaceBetween'
} as const;
export type ButtonAlignType = keyof typeof ButtonAlign;

export const ButtonBottomBarSize = {
  small: 'small',
  medium: 'medium',
} as const;
export type ButtonBottomBarSizeType = keyof typeof ButtonBottomBarSize;

export const ButtonFontWeight = {
  regular: 'regular',
  bold: 'bold'
} as const;
export type ButtonFontWeightType = keyof typeof ButtonFontWeight;
